import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'HomeShow',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Home/Show')
        }
    },
    {
        path: '/education',
        name: 'PageEducation',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Page/Education.vue')
        }
    },
    {
        path: '/games',
        name: 'GamesList',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Game/Games.vue')
        }
    },
    {
        path: '/games/map',
        name: 'GamesMap',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Game/Map.vue')
        }
    },
    {
        path: '/games/maptrash',
        name: 'GamesMapTrash',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Game/MapTrash.vue')
        }
    },
    {
        path: '/games/rebus',
        name: 'GamesRebus',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Game/Rebus.vue')
        }
    },
    {
        path: '/games/who',
        name: 'GamesWho',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Game/Who.vue')
        }
    },
    {
        path: '/games/millionaire',
        name: 'GamesMillionaire',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Game/Millionaire.vue')
        }
    },
    {
        path: '/offer',
        name: 'PageOffer',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Page/Offer.vue')
        }
    },
    {
        path: '/about',
        name: 'PageAbout',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Page/About.vue')
        }
    },
    {
        path: '/faq',
        name: 'PageFAQ',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Page/FAQ.vue')
        }
    },
    {
        path: '/guide',
        name: 'PageGuide',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Page/Guide.vue')
        }
    },
    {
        path: '/articles',
        name: 'ArticlesList',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Articles/List.vue')
        }
    },
    {
        path: '/articles/show/:id',
        name: 'ArticlesShow',
        meta: {
            'layout': 'Standard',
        },
        props: true,
        component() {
            return import('@/views/Articles/Show.vue')
        }
    },
    {
        path: '/text/:id',
        name: 'TextShow',
        meta: {
            'layout': 'Standard',
        },
        props: true,
        component() {
            return import('@/views/Text/Show.vue')
        }
    },
    {
        path: '/events',
        name: 'EventsList',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Events/List.vue')
        }
    },
    {
        path: '/events/show/:id',
        name: 'EventsShow',
        meta: {
            'layout': 'Standard',
        },
        props: true,
        component() {
            return import('@/views/Events/Show.vue')
        }
    },
    {
        path: '/places',
        name: 'PlacesList',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Places/List.vue')
        }
    },
    {
        path: '/places/show/:id',
        name: 'PlacesShow',
        meta: {
            'layout': 'Standard',
        },
        props: true,
        component() {
            return import('@/views/Places/Show.vue')
        }
    },
    {
        path: '/galleries',
        name: 'GalleriesList',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Galleries/List.vue')
        }
    },
    {
        path: '/galleries/show/:id',
        name: 'GalleriesShow',
        meta: {
            'layout': 'Standard',
        },
        props: true,
        component() {
            return import('@/views/Galleries/Show.vue')
        }
    },
    {
        path: '/galleries/:id_category',
        name: 'GalleriesListCat',
        meta: {
            'layout': 'Standard',
        },
        props: true,
        component() {
            return import('@/views/Galleries/List.vue')
        }
    },
    {
        path: '/map',
        name: 'MyMapShow',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/MyMap/Show.vue')
        }
    },
    {
        path: '/contact',
        name: 'ContactShow',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Contact/Show.vue')
        }
    },
    {
        path: '/about',
        name: 'AboutShow',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/About/Show.vue')
        }
    },
    {
        path: '/lessons',
        name: 'LessonsList',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Lessons/List.vue')
        }
    },
    {
        path: '/lessons/accept',
        name: 'LessonsListAccept',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Lessons/ListAccept.vue')
        }
    },
    {
        path: '/lessons/new',
        name: 'LessonsNew',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Lessons/New.vue')
        }
    },
    {
        path: '/lessons/edit/:id',
        name: 'LessonsEdit',
        props: true,
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Lessons/Edit.vue')
        }
    },
    {
        path: '/lessons/show/:id',
        name: 'LessonsShow',
        props: true,
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Lessons/Show.vue')
        }
    },
    {
        path: '/lessons/plans',
        name: 'PlansList',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Lessons/Plans/List.vue')
        }
    },
    {
        path: '/lessons/plans/new',
        name: 'PlansNew',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Lessons/Plans/New.vue')
        }
    },
    {
        path: '/lessons/plans/edit/:id',
        name: 'PlansEdit',
        props: true,
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Lessons/Plans/Edit.vue')
        }
    },
    {
        path: '/lessons/plans/show/:id',
        name: 'PlansShow',
        props: true,
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Lessons/Plans/Show.vue')
        }
    },
    {
        path: '/lessons/modules/',
        name: 'ModulesListAll',
        props: true,
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Lessons/Modules/List.vue')
        }
    },
    {
        path: '/lessons/modules/:id_module_type',
        name: 'ModulesList',
        props: true,
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Lessons/Modules/List.vue')
        }
    },
    {
        path: '/lessons/modules/accept',
        name: 'ModulesListAccept',
        props: true,
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Lessons/Modules/ListAccept.vue')
        }
    },
    {
        path: '/lessons/modules/new',
        name: 'ModulesNew',
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Lessons/Modules/New.vue')
        }
    },
    {
        path: '/lessons/modules/edit/:id',
        name: 'ModulesEdit',
        props: true,
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Lessons/Modules/Edit.vue')
        }
    },
    {
        path: '/lessons/modules/show/:id',
        name: 'ModulesShow',
        props: true,
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Lessons/Modules/Show.vue')
        }
    },
    {
        path: '/profile/lessons',
        name: 'ProfileLessonsList',
        props: true,
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Profile/LessonsList.vue')
        }
    },
    {
        path: '/profile/lessons/modules',
        name: 'ProfileModulesList',
        props: true,
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Profile/ModulesList.vue')
        }
    },
    {
        path: '/profile/lessons/plans',
        name: 'ProfilePlansList',
        props: true,
        meta: {
            'layout': 'Standard',
        },
        component() {
            return import('@/views/Profile/PlansList.vue')
        }
    }


]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {


    /**
     * Określa czy element docelowy wymaga autoryzacji
     * @type {boolean}
     */
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    /**
     * Określa czy element docelowy jest dostępny tylko bez autoryzacji
     * @type {boolean}
     */
    const requiresNoAuth = to.matched.some(record => record.meta.requiresNoAuth);

    /**
     * Wartość tokena z localStorage
     * @type {string}
     */
    const token = localStorage.getItem('token');

    // console.log(requiresAuth, token)

    /**
     * Jeśli strona wymaga autoryzacji a obecnie jej nei ma przenosi do logowania
     */
    if (requiresAuth && !token) {
        next('/login');
    }

    /**
     * Jeśli strona nie chce autoryzowanych użytkowników a obecnie jest, przejdź moje konto
     */
    else if (requiresNoAuth && token) {
        next('/');
    }

    /**
     * W innym wypadku przejdź tam gdzie szedłeś
     */
    else {
        next();
    }
});

router.afterEach((to, from) => {
  const toDepth = to.path.split('/').length
  const fromDepth = from.path.split('/').length
  to.meta.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
})

export default router