<template>

    <div class="btn" @click="context_menu_show = !context_menu_show">
        <span class="material-symbols-outlined material-symbols-outlined--hover">accessibility_new</span>

        <ContextMenu :show="context_menu_show">
            <template #default>
                <div class="wcag">
                    <div class="wcag__item" @click="changeContrast">
                        <span class="material-symbols-outlined">contrast</span>
                        <span class="wcag__item-name">Wysoki kontrast</span>
                        <div class="dots">
                            <span class="dots__item" :class="{'dots__item--active': wcag_config['contrast']}"></span>
                        </div>
                    </div>

                    <div class="wcag__item" @click="changeAnimation">
                        <span class="material-symbols-outlined">pause_circle</span>
                        <span class="wcag__item-name">Brak animacji</span>
                        <div class="dots">
                            <span class="dots__item" :class="{'dots__item--active': wcag_config['animation']}"></span>
                        </div>
                    </div>

                    <div class="wcag__item" @click="changeFontSize">
                        <span class="material-symbols-outlined">text_increase</span>
                        <span class="wcag__item-name">Rozmiar czcionki</span>
                        <div class="dots">
                            <span class="dots__item" :class="{'dots__item--active': wcag_config['font_size'] === 'small' || wcag_config['font_size'] === 'normal' || wcag_config['font_size'] === 'max'}"></span>
                            <span class="dots__item" :class="{'dots__item--active': wcag_config['font_size'] === 'normal' || wcag_config['font_size'] === 'max'}"></span>
                            <span class="dots__item" :class="{'dots__item--active': wcag_config['font_size'] === 'max'}"></span>
                        </div>
                    </div>

                    <div class="wcag__item" @click="changeLetterSpacing">
                        <span class="material-symbols-outlined">format_letter_spacing</span>
                        <span class="wcag__item-name">Odstępy</span>
                        <div class="dots">
                            <span class="dots__item" :class="{'dots__item--active': wcag_config['letter_spacing'] === 'small' || wcag_config['letter_spacing'] === 'normal' || wcag_config['letter_spacing'] === 'max'}"></span>
                            <span class="dots__item" :class="{'dots__item--active': wcag_config['letter_spacing'] === 'normal' || wcag_config['letter_spacing'] === 'max'}"></span>
                            <span class="dots__item" :class="{'dots__item--active': wcag_config['letter_spacing'] === 'max'}"></span>
                        </div>
                    </div>
                </div>
            </template>
        </ContextMenu>
    </div>

</template>

<script>
import ContextMenu from "@/views/Components/ContextMenu.vue";
import View from "@/views/View.vue"

export default {
    name: "WCAG",
    components: {
        ContextMenu,
    },
    extends: View,
    data: () => ({
        context_menu_show: false,

        wcag_config: {
            contrast: false,
            animation: false,
            font_size: "small",
            letter_spacing: "small",
        }
    }),
    methods: {
        changeContrast() {
            this.wcag_config["contrast"] = !this.wcag_config["contrast"]
            let html_tag = document.querySelector(":root")

            if (this.wcag_config["contrast"])
                html_tag.classList.add("contrast")

            if (!this.wcag_config["contrast"])
                html_tag.classList.remove("contrast")
        },

        changeAnimation() {
            this.wcag_config["animation"] = !this.wcag_config["animation"]
            let html_tag = document.querySelector(":root")

            if (this.wcag_config["animation"])
                html_tag.classList.add("transition-off")

            if (!this.wcag_config["animation"])
                html_tag.classList.remove("transition-off")
        },

        changeFontSize() {
            let self = this
            let html_tag = document.querySelector(":root")

            switch (this.wcag_config["font_size"]) {
                case "small":
                    self.wcag_config["font_size"] = "normal"
                    html_tag.style.fontSize = "17px"
                    break
                case "normal":
                    self.wcag_config["font_size"] = "max"
                    html_tag.style.fontSize = "19px"
                    break
                case "max":
                    self.wcag_config["font_size"] = "small"
                    html_tag.style.fontSize = "15px"
                    break
            }
        },

        changeLetterSpacing() {
            let self = this
            let html_tag = document.querySelector(":root")

            switch (this.wcag_config["letter_spacing"]) {
                case "small":
                    self.wcag_config["letter_spacing"] = "normal"
                    html_tag.style.letterSpacing = "2px"
                    break
                case "normal":
                    self.wcag_config["letter_spacing"] = "max"
                    html_tag.style.letterSpacing = "4px"
                    break
                case "max":
                    self.wcag_config["letter_spacing"] = "small"
                    html_tag.style.letterSpacing = "0"
                    break
            }
        }
    }
}
</script>

<style scoped lang="scss">

.btn {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    background-image: -webkit-linear-gradient( 0deg, #007534 0%, #007534 100%);
    box-shadow: 2.5px 4.33px 15px 0 rgba(0,0,0,.15);
    position: relative;

    @media screen and (max-width: 1200px) {
        width: 48px;
        height: 48px;
    }

    @media screen and (max-width: 767px) {
        width: 40px;
        height: 40px;
    }

    & .material-symbols-outlined {
        font-size: 1.6rem;
        color: #fff;

        @media screen and (max-width: 767px) {
            font-size: 1.2rem;
        }
    }

    &:hover {
        background-image: -webkit-linear-gradient( 0deg, #007534 0%, #007534 100%);
    }
}

.wcag {
    background-color: #fff;
    border-top: 4px solid #007534;
    box-shadow: 0 10px 15px rgba(25,25,25,.1);

    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 15px;

        border-bottom: 1px solid #ddd;
        padding: 15px;

        cursor: pointer;

        &-name, & .material-symbols-outlined {
            transition: all 0.25s ease 0s;
            color: #4f4f4f;
        }

        &-name {
            font-size: 1rem;

        }

        & .material-symbols-outlined {
            font-size: 1.6rem;
        }

        &:last-child {
            border-bottom: none;
        }

        &:hover {

            & .wcag__item-name, & .material-symbols-outlined {
                color: #007534;
            }
        }
    }
}

.dots {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    margin-left: auto;

    &__item {
        width: 8px;
        height: 8px;
        background-color: lightgrey;

        &--active {
            background-color: #007534;
        }
    }
}

html.contrast {

    & .btn {
        background-image: -webkit-linear-gradient( 0deg, gold 0%, gold 100%);

        & .material-symbols-outlined {
            color: #000;
        }

        &:hover {
            background-image: -webkit-linear-gradient( 0deg, gold 0%, gold 100%);
        }
    }

    & .wcag {
        background-color: #000;
        border-top-color: gold;
        border-left: 1px solid gold;
        border-right: 1px solid gold;
        border-bottom: 1px solid gold;

        &__item {

            &-name, & .material-symbols-outlined {
                color: #fff;
            }

            &:hover {

                & .wcag__item-name, & .material-symbols-outlined {
                    color: gold;
                }
            }
        }
    }

    & .dots {

        &__item {
            background-color: #fff;

            &--active {
                background-color: gold;
            }
        }
    }
}


</style>