import axios from 'axios'

let headers = {
    // 'Access-Control-Allow-Credentials': 'true',
    // 'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
    // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Origin': true,
    'Content-Type': 'application/json',
};

/**
 * Wartość tokena z localStorage
 * @type {string}
 */
const token = localStorage.getItem('token');

if (token) {
    headers['token'] = token;
}
headers['Domain-id'] = 30;
headers['Domain-id'] = 9137;
// baseURL: 'http://cem.strefaportali.pl/app/api.php/',

export default axios.create({
    baseURL: 'https://app.strefaportali.pl/',
    crossDomain: true,
    mode: 'no-cors',
    headers: headers
})
